export default [
    {
        "fa": "fab fa-facebook",
        "value": "Facebook",
        "linkTo": "https://www.facebook.com/ArizonaRadiology"
    },
    {
        "fa": "fab fa-instagram",
        "value": "Instagram",
        "linkTo": "https://www.instagram.com/arizonaradiology/"
    },
    {
        "fa": "fab fa-tiktok",
        "value": "TikTok",
        "linkTo": "https://www.tiktok.com/@arizonaradiology"
    },
    {
        "fa": "fa-brands fa-threads",
        "value": "Threads",
        "linkTo": "https://www.threads.net/@arizonaradiology"
    },
    {
        "fa": "fa-brands fa-x-twitter",
        "value": "Twitter",
        "linkTo": "https://twitter.com/AZ_Radiology"
    },
    {
        "fa": "fab fa-linkedin",
        "value": "Linkedin",
        "linkTo": "https://www.linkedin.com/company/arizonaradiology"
    },
    {
        "fa": "fab fa-youtube",
        "value": "YouTube",
        "linkTo": "https://www.youtube.com/@arizonaradiology"
    },
    {
        "fa": "fas fa-globe",
        "value": "Our Website",
        "linkTo": "https://www.ArizonaDiagnosticRadiology.com"
    },
    {
        "fa": "fas fa-globe",
        "value": "Enhanced Breast Cancer Detection (EBCD)",
        "linkTo": "https://myebcdmammo.com"
    },
    {
        "fa": "fas fa-microphone",
        "value": "Your Body Podcast",
        "linkTo": "https://www.yourbodyshow.com/"
    },
    {
        "fa": "fa fa-briefcase",
        "value": "Careers",
        "linkTo": "https://recruiting.adp.com/srccar/public/RTI.home?d=ExternalCareerSite&_icx=v02sHjXFgyORaG9NzjgU1V7WP6C76fajz36265hkrEW0f7HfcA1aqFS8xjwZcaj1ycI&c=1205201&_dissimuloSSO=3YqeEL4uVVU:a6rKOjdykHtRbcOe4NHq89b3wQA&state=AZ"
    },
    {
        "fa": "fas fa-lungs",
        "value": "Lung Cancer Screening",
        "linkTo": "https://www.arizonadiagnosticradiology.com/services/low-dose-lung-ct-scan"
    },
    {
        "fa": "fas fa-globe",
        "value": "XRayHours.com",
        "linkTo": "https://xrayhours.com/az"
    },
    {
        "fa": "fas fa-globe",
        "value": "Prostate Program",
        "linkTo": "https://prostate.radnetimaging.com/"
    },
    {
        "fa": "fas fa-ribbon",
        "value": "High-Risk Breast Program",
        "linkTo": "https://www.arizonadiagnosticradiology.com/services/genetic-testing/"
    }
]